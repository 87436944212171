import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import OfficeMap from "../components/OfficeMap"
import ContactForm from "../components/ContactForm"
import { BASE_BREADCRUMBS } from "../constants"
import { parse } from "query-string"
import "../page-styles/contact.scss"

const ContactPage = ({ location }) => {
  const { state = {} } = location
  const { interested_in } = parse(location.search)

  const [selectedProd, setSelectedProd] = useState()

  useEffect(() => {
    if (state && state.selectedProduct) setSelectedProd(state.selectedProduct)
    else if (interested_in) setSelectedProd(interested_in)
  }, [state, interested_in])

  return (
    <Layout
      pageTitle={"Contact\xa0Us"}
      breadcrumbs={[...BASE_BREADCRUMBS, { url: "/contact", name: "Contact" }]}
    >
      <Seo
        title="Contact Us | Typenex Medical"
        description="Have a question about our products or pricing? Click here to get in touch with Typenex Medical Supplies."
      />
      <div className="row">
        <div className="col-md-12 mb-3">
          <p>
            Need more information? Ready for a quote? Want to see a sample?
            Typenex&reg; Medical wants to hear from you.
          </p>
          <p>
            The Typenex Medical team is ready to respond to your institution's
            challenges. We combine the right products with smart service to
            create the simple solutions that support your unique processes and
            add healthcare value.
          </p>
          <p>
            Fill out the Typenex Medical contact form, and we will get in
            touch with you.
          </p>
        </div>
      </div>
      <section id="contact" className="row">
        <div className="col-md-8">
          <ContactForm selectedProd={selectedProd} />
        </div>
        <div className="col-md-4">
          <OfficeMap />
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage

